import React, { createContext, useReducer, useContext } from 'react';

export const ACTION_TYPE = {
	SET_GAME_ID: 'SET_GAME_ID',
	CLEAR_GAME_ID: 'CLEAR_GAME_ID',
	SET_GAME_SRC: 'SET_GAME_SRC',
	CLEAR_GAME_SRC: 'CLEAR_GAME_SRC',
	SET_LANGUAGE: 'SET_LANGUAGE',
	SET_DATA_GAMES: 'SET_DATA_GAMES',
	SELECT_FEATURE: 'SELECT_FEATURE'
};

export const SELECTED_FEATURE = {
	EMPTY: null,
	BASE_GAME: 'BASE_GAME',
	CHALLENGES: 'CHALLENGES',
	DROP: 'DROP'
};

const GlobalStateContext = createContext();

const initialState = {
	gameID: null,
	/**
	 * @description game url
	 */
	gameSrc: null,
	language: 'en',
	/**
	 * @description all data games
	 * @param {Array<Object> | null}
	 */
	games: null,
	selectedFeature: SELECTED_FEATURE.EMPTY
};

function reducer(state, action) {
	switch (action.type) {
		case ACTION_TYPE.SET_GAME_ID:
			return { ...state, gameID: action.payload };
		case ACTION_TYPE.CLEAR_GAME_ID:
			return { ...state, gameID: null };
		case ACTION_TYPE.SET_GAME_SRC:
			return { ...state, gameSrc: action.payload };
		case ACTION_TYPE.CLEAR_GAME_SRC:
			return { ...state, gameSrc: null };
		case ACTION_TYPE.SET_LANGUAGE:
			return { ...state, language: action.payload };
		case ACTION_TYPE.SET_DATA_GAMES:
			return { ...state, games: action.payload };
		case ACTION_TYPE.SELECT_FEATURE:
			return { ...state, selectedFeature: action.payload };
		default:
			return state;
	}
}

export function GlobalStateProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, initialState);

	return <GlobalStateContext.Provider value={{ state, dispatch }}>{children}</GlobalStateContext.Provider>;
}

export function useGlobalState() {
	return useContext(GlobalStateContext);
}
