import { IntlProvider, FormattedMessage } from 'react-intl';
import Banner from '../components/Banner';
import mainBanner from '../assets/main-banner.png';
import translations from '../data/languages';
import MainHeaderLink from './MainHeaderLink';

function MainHeader({ gamesRef, gamificationRef, aboutRef, locale, data, category, setCategory }) {
	const messages = translations[locale];

	const scrollToRef = (ref) => {
		ref.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const resetGamesCategory = () => {
		setCategory('');
		gamesRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const games = data.filter((game) => {
		if (category === 'other') {
			return game.GameType !== 'slots' && game.GameType !== 'casual' && game.GameType !== 'scratch';
		} else return game.GameType === category;
	});

	return (
		<IntlProvider locale={locale} messages={messages}>
			<Banner src={mainBanner} />
			<div className="tabs">
				<button className="tab active" onClick={() => resetGamesCategory()}>
					<FormattedMessage id="allGames" /> <span className="tab-number">{data.length}</span>
				</button>
				<button className="tab" onClick={() => scrollToRef(gamificationRef)}>
					<FormattedMessage id="gamification" />
				</button>
				{/*<button className="tab" onClick={() => scrollToRef(aboutRef)}>*/}
				{/*	<FormattedMessage id="aboutUs" />*/}
				{/*</button>*/}
			</div>
			<div ref={gamesRef} className="tabs-filters">
				<MainHeaderLink
					locale={locale}
					category={category}
					setCategory={setCategory}
					gamesCount={games.length}
					name="slots"
				></MainHeaderLink>
				<MainHeaderLink
					locale={locale}
					category={category}
					setCategory={setCategory}
					gamesCount={games.length}
					name="casual"
				></MainHeaderLink>
				<MainHeaderLink
					locale={locale}
					category={category}
					setCategory={setCategory}
					gamesCount={games.length}
					name="dice"
				></MainHeaderLink>
				<MainHeaderLink
					locale={locale}
					category={category}
					setCategory={setCategory}
					gamesCount={games.length}
					name="scratch"
				></MainHeaderLink>
				<MainHeaderLink
					locale={locale}
					category={category}
					setCategory={setCategory}
					gamesCount={games.length}
					name="lottery"
				></MainHeaderLink>
			</div>
		</IntlProvider>
	);
}

export default MainHeader;
