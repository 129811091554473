import { useGlobalState, ACTION_TYPE, SELECTED_FEATURE } from '../hooks/useGlobalState';

const GameCard = ({ gameId, cardImg, playLink, gameDemoLink }) => {
	const { dispatch } = useGlobalState();

	const updateGameSrc = () => dispatch({ type: ACTION_TYPE.SET_GAME_SRC, payload: playLink });
	const updateGameId = () => dispatch({ type: ACTION_TYPE.SET_GAME_ID, payload: gameId });
	const updateSelectedFeature = () =>
		dispatch({ type: ACTION_TYPE.SELECT_FEATURE, payload: SELECTED_FEATURE.BASE_GAME });

	const handleTogglePopup = (event) => {
		event.stopPropagation();
		updateGameId();
		updateGameSrc();
		updateSelectedFeature();
	};

	return (
		<>
			<div
				className="game-card"
				style={{
					backgroundImage: `url("${cardImg}")`
				}}
				onClick={handleTogglePopup}
			></div>
		</>
	);
};

export default GameCard;
