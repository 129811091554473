export default {
	resetFilters: 'Reset Filters',
	noSearchResults: 'No search results',
	noGamesWithSearchEntry: 'There are no games with this search entry.',
	showMore: 'Show more',

	// Filters Game Type
	GameType: 'Game Type',
	slots: 'Slots',
	cards: 'Cards',
	casual: 'Casual',
	dice: 'Dice',
	roulette: 'Roulette',
	scratch: 'Scratch',
	lottery: 'Lottery',

	// Filters Game Features
	GameFeatures: 'Features',
	respins: 'Respins',
	'bonus-game': 'Bonus Game',
	'buy-bonus': 'Buy Bonus',
	'buy-chance': 'Buy Chance',
	'expanding-wild': 'Expanding Wild',
	'gamble-round': 'Gamble Round',
	'in-game-jackpot': 'In-game Jackpot',
	'lines-change': 'Lines Change',
	'pay-both-ways': 'Pay Both Ways',
	'provably-fair': 'Provably Fair',
	'sticky-wilds': 'Sticky Wilds',

	// Filters Volatility
	Volatility: 'Volatility',
	'volatility-low': 'Low',
	'volatility-medium-low': 'Medium-low',
	'volatility-medium': 'Medium',
	'volatility-medium-high': 'Medium-high',
	'volatility-high': 'High',
	'volatility-very-high': 'Very high',

	// Filters GameMechanics
	GameMechanics: 'Mechanics',
	Megaways: 'Megaways',
	Trueways: 'Trueways',
	'Refilling Reels': 'Refilling Reels',
	'Cluster Pays': 'Cluster Pays',
	'Hold&Win': 'Hold&Win',
	Crash: 'Crash',
	'Book-Of': 'Book-Of',

	// Filters PlayLines
	PlayLines: 'Lines',
	1: '1',
	5: '5',
	9: '9',
	10: '10',
	15: '15',
	20: '20',
	25: '25',
	40: '40',
	50: '50',
	100: '100',
	243: '243',
	Megaways: 'Megaways',
	'Cluster Pays': 'Cluster Pays',
	Trueways: 'Trueways',
	'Pays Anywhere': 'Pays Anywhere',

	// Filters Game Theme
	all: 'All',
	GameTheme: 'Theme',
	adventure: 'Adventure',
	animals: 'Animals',
	asia: 'Asia',
	aztec: 'Aztec',
	beer: 'Beer',
	book: 'Book',
	cars: 'Cars',
	chinese: 'Chinese',
	classic: 'Classic',
	clovers: 'Clovers',
	cocktails: 'Cocktails',
	coins: 'Coins',
	dice: 'Dice',
	easter: 'Easter',
	egypt: 'Egypt',
	fantasy: 'Fantasy',
	farm: 'Farm',
	fruits: 'Fruits',
	fun: 'Fun',
	gems: 'Gems',
	gifts: 'Gifts',
	girls: 'Girls',
	gold: 'Gold',
	heist: 'Heist',
	joker: 'Joker',
	lady: 'Lady',
	magic: 'Magic',
	medieval: 'Medieval',
	money: 'Money',
	monster: 'Monster',
	music: 'Music',
	myths: 'Myths',
	oriental: 'Oriental',
	party: 'Party',
	scary: 'Scary',
	sea: 'Sea',
	space: 'Space',
	sport: 'Sport',
	'steam-punk': 'Steam punk',
	sweets: 'sweets',
	tramp: 'Tramp',
	treasure: 'Treasure',
	'wild-west': 'wild-west',
	xmas: 'Xmas',

	// Other buttons
	playDemo: 'Play demo',
	backToGames: 'BACK TO GAMES',
	goUp: 'Go up',
	search: 'Search',
	back: 'BACK',
	similarGames: 'SIMILAR GAMES',
	play: 'Play',

	allGames: 'ALL GAMES',
	gamification: 'GAMIFICATION',
	aboutUs: 'ABOUT US',
	casinoBeatsTitle: 'Casinobeats game developer awards 2023',
	bestSoftwareProvider: 'Best software provider',
	bestNewSlot: 'Best new slot',
	bgamingThumbs: 'BGAMING & THUMBS',
	bgamingThumbsText:
		'Our "When Art Meets Gaming" initiative takes its first bright step with the talented London-based artist Thumbs. With his distinctive pop culture-inspired style, he has organically reimagined BGaming game characters like Elvis Frog, Joker Queen, and Cactus Cowboy Johnny Cash, adding a special flavor to our experience at the ICE London 2023.',
	other: 'other',
	letStartConversation: 'Let’s start a conversation',
	wouldYouLikeToStartConversation:
		'Would you like to start conversation with BGaming in any field? BGamers are happy to speak with you!',

	//details
	releaseDate: 'RELEASE DATE',
	maxMultiplier: 'Max. multiplier',
	maxWin: 'Max. Win',

	//drops description
	dropsTitle: 'BGaming Drops',
	dropsSubtitle:
		'BGaming Drops is a powerful tool for online gaming operators aiming to enhance player engagement and retention. By offering random, customizable rewards, BGaming Drops not only makes gameplay more exciting but also provides operators with a flexible and effective marketing solution. Whether for seasonal promotions or regular player incentives, BGaming Drops can be tailored to meet the unique needs of any gaming platform.',

	dropsLoyaltyTitle: 'Increased Player Loyalty',
	dropsLoyaltySubtitle: 'Regular rewards keep players coming back, fostering loyalty and increasing lifetime value',

	dropsToolTitle: 'Flexible Marketing Tool',
	dropsToolSubtitle:
		'Operators can tailor drop campaigns to align with their promotional goals and player preferences',

	dropsMotivatesTitle: 'Boosted Gameplay',
	dropsMotivatesSubtitle: 'The anticipation of receiving a drop motivates players to extend their gaming sessions',

	dropsExperienceTitle: 'Enhanced Player Experience',
	dropsExperienceSubtitle:
		'Drops add an extra layer of excitement and reward, enriching the overall gaming experience',

	//challenges description
	challengesTitle: 'BGaming Challenges',
	challengesSubtitle:
		'BGaming Challenges is an innovative feature designed to elevate player engagement and retention by introducing a series of exciting and rewarding in-game challenges. This tool allows operators to create customized challenges within BGaming titles, providing players with additional goals and rewards beyond standard gameplay.',

	challengesMotivationTitle: 'Increased Player Motivation',
	challengesMotivationSubtitle:
		'Challenges provide players with additional goals, motivating them to play more and achieve milestones',

	challengesToolTitle: 'Flexible Marketing Tool',
	challengesToolSubtitle:
		'Operators can design challenges that align with their marketing strategies and target player preferences',

	challengesRatesTitle: 'Higher Engagement Rates',
	challengesRatesSubtitle: 'Engaging and diverse challenges encourage players to spend more time in the game',

	challengesExperienceTitle: 'Improved Player Experience',
	challengesExperienceSubtitle:
		'Challenges add depth and excitement to gameplay, enhancing the overall player experience',
	freeSpins: 'Free spins',
	giveGenerousPraiseToYour:
		'Give generous praise to your players without any stress. Our Free Spin Bonus Deduction program will allow you to organize regular bonuses for your players effortlessly for you.',
	leverageTheFullPotential:
		'Leverage the full potential of this simple, yet powerful tool for rewarding and retaining your players.',
	engage: 'Engage',
	letThePlayersEasilyTryNewContent: 'Let the players easily try new content',
	reward: 'Reward',
	addWelcomePackageOrBonusProgram: 'Add welcome package or bonus program',
	retain: 'Retain',
	freeSpinsIsAGreatTool: 'Free Spins is a great tool for player retention',
	gotIt: 'Got it',
	//similar
	similarGamesTitle: 'You might also like',
	randomGame: 'Random game'
};
