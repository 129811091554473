import React, { useEffect, useState } from 'react';
import style from './GameModal.module.css';
import Modal from '../../components/Modal';
import { useGlobalState, ACTION_TYPE, SELECTED_FEATURE } from '../../hooks/useGlobalState';
import { FormattedMessage } from 'react-intl';
import StickyMenu from '../StyckyModal/StickyMenu';
import GameDetails from '../../components/GameDetails/GameDetails';
import GameFeatureDescription from '../../components/GameFeatureDescription/GameFeatureDescription';
import SimilarGames from '../../components/SimilarGames/SimilarGames';

const GameModal = ({ handleLanguageChange, setActiveGameFilters, handlePlayLink, updateOverflow }) => {
	const [stateSimilarGames, setStateSimilarGames] = useState(null);
	const [isShowSimilarGames, setShowSimilarGames] = useState(false);
	const { state, dispatch } = useGlobalState();

	const clearGameSrc = () => {
		dispatch({ type: ACTION_TYPE.CLEAR_GAME_SRC });
	};

	const clearGameSrcAndOpenSearch = () => {
		setActiveGameFilters(true);
	};

	const overflowHidden = () => (document.body.style.overflow = 'hidden');
	const overflowAuto = () => (document.body.style.overflow = 'auto');
	useEffect(() => {
		if (state.gameSrc || updateOverflow) {
			overflowHidden();
		} else {
			overflowAuto();
		}

		return () => {
			overflowHidden();
			setShowSimilarGames(false);
		};
	}, [state.gameSrc, updateOverflow]);

	useEffect(() => {
		if (state.gameID) {
			const selectedGame = state.games.find((game) => game.GameID === state.gameID);

			const similarGames = state.games.filter(
				(game) => game.GameType === selectedGame.GameType && game.GameID !== state.gameID
			);

			setStateSimilarGames(similarGames.length ? similarGames : null);
		}
	}, [state.gameID]);

	if (!state.gameSrc) {
		return null;
	}

	const ChildComponent = () => {
		const components = {
			[SELECTED_FEATURE.BASE_GAME]: <GameDetails />,
			[SELECTED_FEATURE.CHALLENGES]: <GameFeatureDescription selectedFeature={SELECTED_FEATURE.CHALLENGES} />,
			[SELECTED_FEATURE.DROP]: <GameFeatureDescription selectedFeature={SELECTED_FEATURE.DROP} />
		};
		return components[state.selectedFeature] || null;
	};

	const handleSimilarButton = () => {
		setShowSimilarGames((prevState) => !prevState);
	};

	const SimilarButton = () => (
		<button className={`${style.button} ${style.button_similar}`} onClick={handleSimilarButton}>
			<FormattedMessage id="similarGames" />
		</button>
	);
	/**
	 * @description after popup with filter, overflow: "auto"
	 */
	overflowHidden();

	return (
		<Modal className={style.modal}>
			<div className={style.template}>
				<div className={style.iframe_wrapper}>
					<iframe
						className={style.iframe}
						src={state.gameSrc}
						title="game"
						allow="autoplay"
						width="100%"
						height="100%"
					/>
				</div>
				<div className={style.content_template}>
					<div className={style.button_container}>
						<button className={`${style.button} ${style.button_back}`} onClick={clearGameSrc}>
							← <FormattedMessage id="back" />
						</button>
						{stateSimilarGames && <SimilarButton />}
					</div>
					<ChildComponent />
					<SimilarGames
						isVisible={isShowSimilarGames}
						similarGames={stateSimilarGames}
						handlePlayLink={handlePlayLink}
					/>
					<div className={style.empty_block}></div>
					<StickyMenu
						onLanguageChange={handleLanguageChange}
						setActiveGameFilters={clearGameSrcAndOpenSearch}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default GameModal;
