import { IntlProvider, FormattedMessage } from 'react-intl';
import '../assets/styles/GamesCategory.css';
import { useEffect, useState } from 'react';
import translations from '../data/languages';
import GameCard from '../components/GameCard';

const GAMES_PER_PAGE = 25;

function GamesExpand({ data, category, locale, handlePlayLink }) {
	const [next, setNext] = useState(GAMES_PER_PAGE);
	const [isShowMore, setShowMore] = useState(true);

	const messages = translations[locale];

	const games = data.filter((game) => {
		if (category === 'other') {
			return game.GameType !== 'slots' && game.GameType !== 'casual' && game.GameType !== 'scratch';
		} else return game.GameType === category;
	});

	useEffect(() => {
		setNext(GAMES_PER_PAGE);
	}, [games.length]);

	useEffect(() => {
		if (games.length >= next) {
			setShowMore(true);
		} else {
			setShowMore(false);
		}
	}, [next, games]);

	const handleLoadMore = () => {
		setNext(next + GAMES_PER_PAGE);
	};

	return (
		<IntlProvider locale={locale} messages={messages}>
			<div className="games-category-title">
				<FormattedMessage id={category} />
			</div>

			<div className="games-category-list">
				{games.length > 0 &&
					games.slice(0, next).map((game) => {
						if (game.GameID) {
							return (
								<GameCard
									key={game.ItemID}
									gameId={game.GameID}
									cardImg={game.cardImg}
									playLink={handlePlayLink(game.GameID)}
									gameDemoLink={game.GameDemoLink}
								/>
							);
						}
					})}
			</div>

			<div className="games">
				<div className="navigation">
					{isShowMore && (
						<button className="more-btn" onClick={handleLoadMore}>
							<FormattedMessage id="showMore" />
						</button>
					)}
				</div>
			</div>
		</IntlProvider>
	);
}

export default GamesExpand;
