import { IntlProvider } from 'react-intl';
import translations from '../data/languages';
import GameTypesFilter from './GameTypesFilter';

function GameTypesFilters({ locale, setGameType, gameType }) {
	const messages = translations[locale];

	return (
		<IntlProvider locale={locale} messages={messages}>
			<div className="games-filters-tabs">
				<GameTypesFilter
					locale={locale}
					gameType={gameType}
					setGameType={setGameType}
					name="all"
				></GameTypesFilter>
				<GameTypesFilter
					locale={locale}
					gameType={gameType}
					setGameType={setGameType}
					name="slots"
				></GameTypesFilter>
				<GameTypesFilter
					locale={locale}
					gameType={gameType}
					setGameType={setGameType}
					name="dice"
				></GameTypesFilter>
				<GameTypesFilter
					locale={locale}
					gameType={gameType}
					setGameType={setGameType}
					name="casual"
				></GameTypesFilter>
				<GameTypesFilter
					locale={locale}
					gameType={gameType}
					setGameType={setGameType}
					name="scratch"
				></GameTypesFilter>
				<GameTypesFilter
					locale={locale}
					gameType={gameType}
					setGameType={setGameType}
					name="lottery"
				></GameTypesFilter>
			</div>
		</IntlProvider>
	);
}

export default GameTypesFilters;
