import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const FilterItemsList = ({ id, filters, onChange }) => {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const ref = useRef('filter-dropdown');

	useEffect(() => {
		const dropdown = ref?.current;
		if (ref) {
			const rect = dropdown.getBoundingClientRect();
			const isOverflowing = dropdown.clientWidth + rect.x > document.documentElement.clientWidth;
			setIsOverflowing(isOverflowing);
		}
	}, [id]);

	return (
		<nav
			id={id}
			className="filter-items-list"
			ref={ref}
			style={{
				right: isOverflowing ? 0 : 'auto'
			}}
		>
			{filters.map((filter, index) => (
				<label key={index} className="filter-checkbox">
					<input
						id={filter.value}
						checked={filter.checked}
						type="checkbox"
						className="custom-hide-checkbox"
						onChange={() => onChange(index)}
					/>
					<div className="custom-checkbox" />
					<span className="custom-checkbox-label">
						<FormattedMessage id={filter.value} />
					</span>
				</label>
			))}
		</nav>
	);
};

export default FilterItemsList;
