import { useEffect } from 'react';

export function useClickOutside(ref, onClickOutside) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				onClickOutside();
			}
		}
		// Bind
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// dispose
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, onClickOutside]);
}
