export class Filters {
	constructor() {
		this.activeFilters = {
			gameType: false,
			gameFeatures: false,
			mechanics: false,
			lines: false,
			theme: false,
			volatility: false
		};

		this.prevFilters = [];
	}

	checkActiveFilters() {
		return Object.values(this.activeFilters).some((item) => item === true);
	}

	isOtherActive(id) {
		const filtersObject = { ...this.activeFilters };
		delete filtersObject[id];
		return Object.values(filtersObject).some((item) => item === true) && this.prevFilters.length;
	}

	handleGameTypeFilters(data, initialData, filters) {
		const checkedFilters = filters.filter((item) => item.checked).map((item) => item['value']);

		if (!checkedFilters.length) {
			this.activeFilters.gameType = false;
			return this.checkActiveFilters() ? data : initialData;
		} else {
			this.activeFilters.gameType = true;
		}

		const filteredData = this.isOtherActive('gameType')
			? this.prevFilters.filter((dataItem) => checkedFilters.includes(dataItem.GameType))
			: initialData.filter((dataItem) => checkedFilters.includes(dataItem.GameType));

		if (filteredData.length) {
			this.prevFilters = filteredData;
		}

		return filteredData;
	}

	handleGameFeaturesFilters(data, initialData, filters) {
		const checkedFilters = filters.filter((item) => item.checked).map((item) => item['value']);

		if (!checkedFilters.length) {
			this.activeFilters.gameFeatures = false;
			return this.checkActiveFilters() ? data : initialData;
		} else {
			this.activeFilters.gameFeatures = true;
		}

		const filteredData = this.isOtherActive('gameFeatures')
			? this.prevFilters.filter((dataItem) =>
					checkedFilters.some((filter) => dataItem?.GameFeatures?.includes(filter))
				)
			: initialData.filter((dataItem) =>
					checkedFilters.some((filter) => dataItem?.GameFeatures?.includes(filter))
				);

		if (filteredData.length) {
			this.prevFilters = filteredData;
		}

		return filteredData;
	}

	handleGameMechanicsFilters(data, initialData, filters) {
		const checkedFilters = filters.filter((item) => item.checked).map((item) => item['value']);

		if (!checkedFilters.length) {
			this.activeFilters.mechanics = false;
			return this.checkActiveFilters() ? data : initialData;
		} else {
			this.activeFilters.mechanics = true;
		}

		const filteredData = this.isOtherActive('mechanics')
			? this.prevFilters.filter(
					(dataItem) =>
						checkedFilters.includes(dataItem.GameMechanics1) ||
						checkedFilters.includes(dataItem.GameMechanics2)
				)
			: initialData.filter(
					(dataItem) =>
						checkedFilters.includes(dataItem.GameMechanics1) ||
						checkedFilters.includes(dataItem.GameMechanics2)
				);

		if (filteredData.length) {
			this.prevFilters = filteredData;
		}

		return filteredData;
	}

	handleLinesFilter(data, initialData, filters) {
		const checkedFilters = filters.filter((item) => item.checked).map((item) => item['value']);

		if (!checkedFilters.length) {
			this.activeFilters.lines = false;
			return this.checkActiveFilters() ? data : initialData;
		} else {
			this.activeFilters.lines = true;
		}

		const filteredData = this.isOtherActive('lines')
			? this.prevFilters.filter((dataItem) => checkedFilters.includes(dataItem.Lines))
			: initialData.filter((dataItem) => checkedFilters.includes(dataItem.Lines));

		if (filteredData.length) {
			this.prevFilters = filteredData;
		}

		return filteredData;
	}

	handleGameThemeFilters(data, initialData, filters) {
		const checkedFilters = filters.filter((item) => item.checked).map((item) => item['value']);

		if (!checkedFilters.length) {
			this.activeFilters.theme = false;
			return this.checkActiveFilters() ? data : initialData;
		} else {
			this.activeFilters.theme = true;
		}

		const filteredData = this.isOtherActive('theme')
			? this.prevFilters.filter((dataItem) =>
					checkedFilters.some((filter) => dataItem?.GameTheme?.includes(filter))
				)
			: initialData.filter((dataItem) => checkedFilters.some((filter) => dataItem?.GameTheme?.includes(filter)));

		if (filteredData.length) {
			this.prevFilters = filteredData;
		}

		return filteredData;
	}

	handleVolatilityFilters(data, initialData, filters) {
		const checkedFilters = filters.filter((item) => item.checked).map((item) => item['value']);

		if (!checkedFilters.length) {
			this.activeFilters.volatility = false;
			return this.checkActiveFilters() ? data : initialData;
		} else {
			this.activeFilters.volatility = true;
		}

		const filteredData = this.isOtherActive('volatility')
			? this.prevFilters.filter((dataItem) => checkedFilters.includes(dataItem.Volatility))
			: initialData.filter((dataItem) => checkedFilters.includes(dataItem.Volatility));

		if (filteredData.length) {
			this.prevFilters = filteredData;
		}

		return filteredData;
	}
}
