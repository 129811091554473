import { useEffect, useState, useRef } from 'react';
import FilterItemsList from './FilterItemsList';
import { useClickOutside } from './utils/withClickOutside';
import { FormattedMessage } from 'react-intl';
import dropdown from '../assets/dropdown.svg';

const FilterItem = ({ id, filter, setFilters }) => {
	const [filterCount, setFilterCount] = useState(0);
	const [open, setOpen] = useState(false);
	const wrapperRef = useRef('dropdown');

	useClickOutside(wrapperRef, () => {
		setOpen(false);
	});

	const handleToggleDropdown = () => {
		setOpen((state) => !state);
	};

	useEffect(() => {
		const checkedFilters = filter.filters.filter((item) => item.checked);
		setFilterCount(checkedFilters.length);
	}, [filter]);

	const onChange = (index) => {
		const updatedFilters = filter.filters.map((item, i) => {
			if (i === index) {
				return {
					...item,
					checked: !item.checked
				};
			} else {
				return item;
			}
		});

		const isActive = updatedFilters.some((item) => item.checked);

		setFilters({
			...filter,
			touched: isActive ? true : false,
			filters: updatedFilters
		});
	};

	return (
		<div className="filter-dropdown" ref={wrapperRef}>
			<div className="dropdown-toggle" className={open ? 'dropdown-toggle opened' : 'dropdown-toggle'} onClick={handleToggleDropdown}>
				<div className="filter-title">
					<FormattedMessage id={filter.id} />
					<img className="dropdown-img" src={dropdown} alt="" />
				</div>
				{filterCount > 0 && <div className="filter-count">{filterCount}</div>}
			</div>
			{open && <FilterItemsList id={filter.id} filters={filter.filters} onChange={onChange} />}
		</div>
	);
};

export default FilterItem;
