import tickCircle from '../assets/tick-circle.svg';
import freeSpinsModal from '../assets/free-spins-modal.png';
import { FormattedMessage } from 'react-intl';
import Modal from './Modal';

const FreeSpinsModal = ({ setFreeSpinsModal }) => {
	const modalID = 'free-spins-modal';

	const handleClick = (event) => {
		const eventPath = event.nativeEvent.composedPath();

		const clickInsideModal = eventPath.some(({ id }) => id === modalID);

		if (!clickInsideModal) setFreeSpinsModal(false);
	};

	return (
		<Modal className="modal-overlay" onClick={handleClick}>
			<div className="free-spins-modal" id={modalID}>
				<div className="free-spins-modal-container">
					<div className="free-spins-content">
						<div className="free-spins-title">
							<FormattedMessage id="freeSpins" />
						</div>
						<div className="free-spins-description">
							<FormattedMessage id="giveGenerousPraiseToYour" />
						</div>
						<div className="free-spins-description free-spins-description-margin">
							<FormattedMessage id="leverageTheFullPotential" />
						</div>
						<div className="free-spins-hint">
							<div className="free-spins-hint-title">
								<img src={tickCircle} alt="" />
								<div>
									<FormattedMessage id="engage" />
								</div>
							</div>
							<div className="free-spins-hint-description">
								<FormattedMessage id="letThePlayersEasilyTryNewContent" />
							</div>
						</div>
						<div className="free-spins-hint">
							<div className="free-spins-hint-title">
								<img src={tickCircle} alt="" />
								<FormattedMessage id="reward" />
							</div>
							<div className="free-spins-hint-description">
								<FormattedMessage id="addWelcomePackageOrBonusProgram" />
							</div>
						</div>
						<div className="free-spins-hint">
							<div className="free-spins-hint-title">
								<img src={tickCircle} alt="" />
								<div>
									<FormattedMessage id="retain" />
								</div>
							</div>
							<div className="free-spins-hint-description">
								<FormattedMessage id="freeSpinsIsAGreatTool" />
							</div>
						</div>
					</div>
					<div className="free-spins-img">
						<img src={freeSpinsModal} alt="" />
					</div>
				</div>

				<div className="free-spins-btn-container">
					<button className="free-spins-btn" onClick={() => setFreeSpinsModal(false)}>
						<FormattedMessage id="gotIt" />
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default FreeSpinsModal;
