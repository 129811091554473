import React from 'react';

const IconSearch = ({ width = 24, height = 24, color = 'black' }) => (
	<svg width={width} height={height} viewBox="0 0 36 36" fill={color} xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4 16C4 9.382 9.382 4 16 4C22.618 4 28 9.382 28 16C28 22.618 22.618 28 16 28C9.382 28 4 22.618 4 16ZM35.414 32.586L28.624 25.794C30.73 23.086 32 19.692 32 16C32 7.178 24.822 0 16 0C7.178 0 0 7.178 0 16C0 24.822 7.178 32 16 32C19.692 32 23.086 30.73 25.794 28.624L32.586 35.414C32.976 35.804 33.488 36 34 36C34.512 36 35.024 35.804 35.414 35.414C36.196 34.632 36.196 33.368 35.414 32.586Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconSearch;
