import { createPortal } from 'react-dom';
import React from 'react';

const htmlElement = document.getElementById('modal');

function Modal({ children, className, onClick }) {
	return createPortal(
		<div className={className} onClick={onClick}>
			{children}
		</div>,
		htmlElement
	);
}

export default Modal;
