export const FILTERS = [
	// {
	// 	id: 'GameType',
	// 	touched: false,
	// 	title: 'Game Type',
	// 	filters: [
	// 		{
	// 			value: 'slots',
	// 			title: 'Slots',
	// 			checked: false
	// 		},
	// 		{
	// 			value: 'cards',
	// 			title: 'Cards',
	// 			checked: false
	// 		},
	// 		{
	// 			value: 'casual',
	// 			title: 'Casual',
	// 			checked: false
	// 		},
	// 		{
	// 			value: 'dice',
	// 			title: 'Dice',
	// 			checked: false
	// 		},
	// 		{
	// 			value: 'roulette',
	// 			title: 'Roulette',
	// 			checked: false
	// 		},
	// 		{
	// 			value: 'scratch',
	// 			title: 'Scratch',
	// 			checked: false
	// 		},
	// 		{
	// 			value: 'lottery',
	// 			title: 'Lottery',
	// 			checked: false
	// 		}
	// 	]
	// },
	{
		id: 'GameFeatures',
		touched: false,
		title: 'Features',
		filters: [
			{
				value: 'bonus-game',
				title: 'Bonus Game',
				checked: false
			},
			{
				value: 'buy-bonus',
				title: 'Buy Bonus',
				checked: false
			},
			{
				value: 'buy-chance',
				title: 'Buy Chance',
				checked: false
			},
			{
				value: 'expanding-wild',
				title: 'Expanding Wild',
				checked: false
			},
			{
				value: 'gamble-round',
				title: 'Gamble Round',
				checked: false
			},
			{
				value: 'in-game-jackpot',
				title: 'In-game Jackpot',
				checked: false
			},
			{
				value: 'lines-change',
				title: 'Lines Change',
				checked: false
			},
			{
				value: 'pay-both-ways',
				title: 'Pay Both Ways',
				checked: false
			},
			{
				value: 'provably-fair',
				title: 'Provably Fair',
				checked: false
			},
			{
				value: 'respins',
				title: 'Respins',
				checked: false
			},
			{
				value: 'sticky-wilds',
				title: 'Sticky Wilds',
				checked: false
			}
		]
	},
	{
		id: 'Volatility',
		touched: false,
		title: 'Volatility',
		filters: [
			{
				value: 'volatility-low',
				title: 'Low',
				checked: false
			},
			{
				value: 'volatility-medium-low',
				title: 'Medium-low',
				checked: false
			},
			{
				value: 'volatility-medium',
				title: 'Medium',
				checked: false
			},
			{
				value: 'volatility-medium-high',
				title: 'Medium-high',
				checked: false
			},
			{
				value: 'volatility-high',
				title: 'High',
				checked: false
			},
			{
				value: 'volatility-very-high',
				title: 'Very high',
				checked: false
			}
		]
	},
	{
		id: 'GameMechanics',
		touched: false,
		title: 'Mechanics',
		filters: [
			{
				value: 'Megaways',
				title: 'Megaways',
				checked: false
			},
			{
				value: 'Trueways',
				title: 'Trueways',
				checked: false
			},
			{
				value: 'Refilling Reels',
				title: 'Refilling Reels',
				checked: false
			},
			{
				value: 'Cluster Pays',
				title: 'Cluster Pays',
				checked: false
			},
			{
				value: 'Hold&Win',
				title: 'Hold&Win',
				checked: false
			},
			{
				value: 'Crash',
				title: 'Crash',
				checked: false
			},
			{
				value: 'Book-Of',
				title: 'Book-Of',
				checked: false
			}
		]
	},
	{
		id: 'Lines',
		touched: false,
		title: 'Lines',
		filters: [
			{
				value: 1,
				title: '1',
				checked: false
			},
			{
				value: 5,
				title: '5',
				checked: false
			},
			{
				value: 9,
				title: '9',
				checked: false
			},
			{
				value: 10,
				title: '10',
				checked: false
			},
			{
				value: 15,
				title: '15',
				checked: false
			},
			{
				value: 20,
				title: '20',
				checked: false
			},
			{
				value: 25,
				title: '25',
				checked: false
			},
			{
				value: 40,
				title: '40',
				checked: false
			},
			{
				value: 50,
				title: '50',
				checked: false
			},
			{
				value: 50,
				title: '50',
				checked: false
			},
			{
				value: 100,
				title: '100',
				checked: false
			},
			{
				value: 243,
				title: '243',
				checked: false
			},
			{
				value: 'Megaways',
				title: 'Megaways',
				checked: false
			},
			{
				value: 'Cluster Pays',
				title: 'Cluster Pays',
				checked: false
			},
			{
				value: 'Trueways',
				title: 'Trueways',
				checked: false
			},
			{
				value: 'Pays Anywhere',
				title: 'Pays Anywhere',
				checked: false
			}
		]
	},
	{
		id: 'GameTheme',
		touched: false,
		title: 'Theme',
		filters: [
			{
				value: 'adventure',
				title: 'Adventure',
				checked: false
			},
			{
				value: 'animals',
				title: 'Animals',
				checked: false
			},
			{
				value: 'asia',
				title: 'Asia',
				checked: false
			},
			{
				value: 'aztec',
				title: 'Aztec',
				checked: false
			},
			{
				value: 'beer',
				title: 'Beer',
				checked: false
			},
			{
				value: 'book',
				title: 'Book',
				checked: false
			},
			{
				value: 'cars',
				title: 'Cars',
				checked: false
			},
			{
				value: 'chinese',
				title: 'Chinese',
				checked: false
			},
			{
				value: 'classic',
				title: 'Classic',
				checked: false
			},
			{
				value: 'clovers',
				title: 'Clovers',
				checked: false
			},
			{
				value: 'cocktails',
				title: 'Cocktails',
				checked: false
			},
			{
				value: 'coins',
				title: 'Coins',
				checked: false
			},
			{
				value: 'dice',
				title: 'Dice',
				checked: false
			},
			{
				value: 'easter',
				title: 'Easter',
				checked: false
			},
			{
				value: 'egypt',
				title: 'Egypt',
				checked: false
			},
			{
				value: 'fantasy',
				title: 'Fantasy',
				checked: false
			},
			{
				value: 'farm',
				title: 'Farm',
				checked: false
			},
			{
				value: 'fruits',
				title: 'Fruits',
				checked: false
			},
			{
				value: 'fun',
				title: 'Fun',
				checked: false
			},
			{
				value: 'gems',
				title: 'Gems',
				checked: false
			},
			{
				value: 'gifts',
				title: 'Gifts',
				checked: false
			},
			{
				value: 'girls',
				title: 'Girls',
				checked: false
			},
			{
				value: 'gold',
				title: 'Gold',
				checked: false
			},
			{
				value: 'heist',
				title: 'Heist',
				checked: false
			},
			{
				value: 'joker',
				title: 'Joker',
				checked: false
			},
			{
				value: 'lady',
				title: 'Lady',
				checked: false
			},
			{
				value: 'magic',
				title: 'Magic',
				checked: false
			},
			{
				value: 'medieval',
				title: 'Medieval',
				checked: false
			},
			{
				value: 'money',
				title: 'Money',
				checked: false
			},
			{
				value: 'monster',
				title: 'Monster',
				checked: false
			},
			{
				value: 'music',
				title: 'Music',
				checked: false
			},
			{
				value: 'myths',
				title: 'Myths',
				checked: false
			},
			{
				value: 'oriental',
				title: 'Oriental',
				checked: false
			},
			{
				value: 'party',
				title: 'Party',
				checked: false
			},
			{
				value: 'scary',
				title: 'Scary',
				checked: false
			},
			{
				value: 'sea',
				title: 'Sea',
				checked: false
			},
			{
				value: 'space',
				title: 'Space',
				checked: false
			},
			{
				value: 'sport',
				title: 'Sport',
				checked: false
			},
			{
				value: 'steam-punk',
				title: 'Steam punk',
				checked: false
			},
			{
				value: 'sweets',
				title: 'Sweets',
				checked: false
			},
			{
				value: 'tramp',
				title: 'Tramp',
				checked: false
			},
			{
				value: 'treasure',
				title: 'Treasure',
				checked: false
			},
			{
				value: 'wild-west',
				title: 'Wild west',
				checked: false
			},
			{
				value: 'xmas',
				title: 'Xmas',
				checked: false
			}
		]
	}
];
