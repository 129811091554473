import { IntlProvider, FormattedMessage } from 'react-intl';
import translations from '../data/languages';

function MainHeaderLink({ locale, name, setCategory, category, gamesCount }) {
	const messages = translations[locale];

	return (
		<IntlProvider locale={locale} messages={messages}>
			<div onClick={() => setCategory(name)}>
				<button className={category === name ? 'tab tab-filter active' : 'tab tab-filter'}>
					<FormattedMessage id={name} />
					{category === name && <span className="tab-number">{gamesCount}</span>}
				</button>
			</div>
		</IntlProvider>
	);
}

export default MainHeaderLink;
