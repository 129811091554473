import { useGlobalState, ACTION_TYPE, SELECTED_FEATURE } from '../hooks/useGlobalState';
import { FormattedMessage } from 'react-intl';

const GameRow = ({ gameName, gameType, cardHorizontalImg, playLink, gameID, handleClick, gameDemoLink }) => {
	const { dispatch } = useGlobalState();

	const updateGameSrc = () => dispatch({ type: ACTION_TYPE.SET_GAME_SRC, payload: playLink });
	const updateGameID = () => dispatch({ type: ACTION_TYPE.SET_GAME_ID, payload: gameID });
	const updateFeatureType = () => dispatch({ type: ACTION_TYPE.SELECT_FEATURE, payload: SELECTED_FEATURE.BASE_GAME });

	const handleTogglePopup = (event) => {
		event.stopPropagation();
		updateGameSrc();
		updateGameID();
		updateFeatureType();
		handleClick();
	};

	return (
		<div className="game-row">
			<div className="game-row-img">
				<img src={cardHorizontalImg} alt="" />
			</div>
			<div className="game-row-content">
				<div className="game-row-title">{gameName}</div>
				<div className="game-row-type">
					<FormattedMessage id={gameType} />
				</div>
				<button className="game-row-btn" onClick={handleTogglePopup}>
					<FormattedMessage id="play" />
				</button>
			</div>
		</div>
	);
};

export default GameRow;
