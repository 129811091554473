import searchInput from '../assets/search-input.svg';
import closeIcon from '../assets/close-icon.svg';
import { useIntl } from 'react-intl';

function GamesSearch({ search, setSearch }) {

	const maxLength= 100;

	const handleInputChange = (event) => {
		setSearch(event.target.value);
	};

	const intl = useIntl();
	const placeholderText = intl.formatMessage({ id: 'search' });

	return (
		<div className="games-search">
			<div className="games-search-loupe">
				<img src={searchInput} alt="" />
			</div>
			<input type="text" placeholder={placeholderText} value={search} onChange={handleInputChange} maxLength={maxLength} />
			<div className="games-search-close" onClick={() => setSearch('')}>
				{search && <img src={closeIcon} alt="" />}
			</div>
		</div>
	);
}
export default GamesSearch;
