import GamesSideBar from './GamesSideBar';
import GameCard from './GameCard';

const GamesList = ({ games, handlePlayLink, setCategoryWithScroll, category }) => {
	return (
		<div className="games-container">
			<div onClick={() => setCategoryWithScroll(category)}>
				<GamesSideBar category={category} />
			</div>
			<div className="games-container-scroll">
				<div className={category === 'slots' ? 'games-list' : 'games-list games-list-nowrap'}>
					{games.length > 0 &&
						games.map((game) => {
							if (game.GameID) {
								return (
									<GameCard
										key={game.ItemID}
										gameId={game.GameID}
										cardImg={game.cardImg}
										playLink={handlePlayLink(game.GameID)}
										gameDemoLink={game.GameDemoLink}
									/>
								);
							}
						})}
				</div>
			</div>
		</div>
	);
};

export default GamesList;
