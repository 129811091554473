import { IntlProvider, FormattedMessage } from 'react-intl';
import translations from '../data/languages';

function GameTypesFilter({ locale, name, setGameType, gameType }) {
	const messages = translations[locale];

	return (
		<IntlProvider locale={locale} messages={messages}>
			<div onClick={() => setGameType(name)}>
				<button className={gameType === name ? 'tab active' : 'tab'}>
					<FormattedMessage id={name} />
				</button>
			</div>
		</IntlProvider>
	);
}

export default GameTypesFilter;
