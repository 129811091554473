import React from 'react';

const IconArrowUp = ({ width = 24, height = 24, color = 'black' }) => (
	<svg width={width} height={height} viewBox="0 0 48 48" fill={color} xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.4638 21.2802C11.1718 22.1282 12.4318 22.2442 13.2798 21.5362L21.9998 14.2702V38.0002C21.9998 39.1042 22.8958 40.0002 23.9998 40.0002C25.1038 40.0002 25.9998 39.1042 25.9998 38.0002V14.2702L34.7198 21.5362C35.5698 22.2442 36.8298 22.1282 37.5358 21.2802C38.2438 20.4322 38.1298 19.1702 37.2798 18.4642L25.2798 8.46424C25.1858 8.38424 25.0738 8.34624 24.9698 8.28624C24.8878 8.23824 24.8158 8.18224 24.7258 8.14624C24.4918 8.05424 24.2478 8.00024 23.9998 8.00024C23.7518 8.00024 23.5078 8.05424 23.2738 8.14624C23.1838 8.18224 23.1118 8.23824 23.0298 8.28624C22.9258 8.34624 22.8138 8.38424 22.7198 8.46424L10.7198 18.4642C9.87182 19.1702 9.75582 20.4322 10.4638 21.2802Z"
			fill="currentColor"
		/>
		<mask
			id="mask0_116_4484"
			style={{ maskType: 'luminance' }}
			maskUnits="userSpaceOnUse"
			x="10"
			y="8"
			width="29"
			height="33"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.4638 21.2802C11.1718 22.1282 12.4318 22.2442 13.2798 21.5362L21.9998 14.2702V38.0002C21.9998 39.1042 22.8958 40.0002 23.9998 40.0002C25.1038 40.0002 25.9998 39.1042 25.9998 38.0002V14.2702L34.7198 21.5362C35.5698 22.2442 36.8298 22.1282 37.5358 21.2802C38.2438 20.4322 38.1298 19.1702 37.2798 18.4642L25.2798 8.46424C25.1858 8.38424 25.0738 8.34624 24.9698 8.28624C24.8878 8.23824 24.8158 8.18224 24.7258 8.14624C24.4918 8.05424 24.2478 8.00024 23.9998 8.00024C23.7518 8.00024 23.5078 8.05424 23.2738 8.14624C23.1838 8.18224 23.1118 8.23824 23.0298 8.28624C22.9258 8.34624 22.8138 8.38424 22.7198 8.46424L10.7198 18.4642C9.87182 19.1702 9.75582 20.4322 10.4638 21.2802Z"
				fill="currentColor"
			/>
		</mask>
		<g mask="url(#mask0_116_4484)"></g>
	</svg>
);

export default IconArrowUp;
