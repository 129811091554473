import styles from './StickyMenu.module.css';
import React, { useState, useEffect } from 'react';
import { IconArrowUp, IconSearch } from '../../assets/icons';
import { useGlobalState, ACTION_TYPE } from '../../hooks/useGlobalState';

function StickyMenu({ isScrollable, setActiveGameFilters }) {
	const { state, dispatch } = useGlobalState();
	const [showScrollButton, setShowScrollButton] = useState(false);
	const [showSwitchLanguageButton, setShowSwitchLanguageButton] = useState(false);
	const [currentLanguage, setCurrentLanguage] = useState(state.language);
	const secondLanguage = currentLanguage === 'en' ? 'es' : 'en';

	const switchLanguage = (language) => {
		dispatch({ type: ACTION_TYPE.SET_LANGUAGE, payload: language });
	};

	const handleLanguageSwitcher = (language) => {
		if (language !== currentLanguage) {
			setCurrentLanguage(language);
			switchLanguage(language);
		}
		setShowSwitchLanguageButton((prev) => !prev);
	};

	const handleScroll = () => {
		setShowScrollButton(window.scrollY > 1000);
	};

	useEffect(() => {
		if (isScrollable) {
			window.addEventListener('scroll', handleScroll);
			return () => window.removeEventListener('scroll', handleScroll);
		}
	}, []);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<div className={styles.modal}>
			<div className={styles.content_wrapper}>
				<div className={styles.content_empty}></div>
				<div className={styles.vertical_wrapper}>
					{showSwitchLanguageButton && (
						<div>
							<div
								className={styles.content_switchable}
								onClick={() => handleLanguageSwitcher(secondLanguage)}
							>
								<span className={styles.icon_wrapper}>
									<span className={styles.icon_text}>{state.language === 'en' ? 'es' : 'en'}</span>
								</span>
							</div>
						</div>
					)}
					<div>
						<div className={styles.content} onClick={() => handleLanguageSwitcher(currentLanguage)}>
							<span className={styles.icon_wrapper}>
								<span className={styles.icon_text}>{state.language}</span>
							</span>
						</div>
					</div>
				</div>
				<div className={styles.content} onClick={() => setActiveGameFilters(true)}>
					<span className={styles.icon_wrapper}>
						<IconSearch width="0.75rem" height="0.75rem" />
					</span>
				</div>
				{showScrollButton && isScrollable && (
					<div className={styles.content} onClick={scrollToTop}>
						<span className={styles.icon_wrapper}>
							<IconArrowUp width="1.1rem" height="1.1rem" />
						</span>
					</div>
				)}
			</div>
		</div>
	);
}

export default StickyMenu;
