import arrowRight from '../assets/arrow-right.svg';
import { FormattedMessage } from 'react-intl';

const GamesSideBar = ({ category }) => {
	return (
		<div className="games-sidebar-wrapper">
			<div className="games-sidebar-container">
				<div className="games-sidebar">
					<div className="games-sidebar-title">
						<FormattedMessage id={category} />
					</div>
					<div className="games-sidebar-subtitle">
						<FormattedMessage id="showMore" />
					</div>
					<div className="games-sidebar-img">
						<img src={arrowRight} alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default GamesSideBar;
