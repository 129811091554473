import React from 'react';
import styles from './GameDetails.module.css';
import { useGlobalState } from '../../hooks/useGlobalState';
import { FormattedMessage } from 'react-intl';

const GameDetails = () => {
	const { state } = useGlobalState();

	const getCurrentDate = (value) => {
		const date = new Date(value);
		const options = { year: 'numeric', month: 'long', day: 'numeric' };
		return date.toLocaleDateString('en-US', options);
	};

	const currentGame = state.games.find(({ GameID }) => state.gameID === GameID) || null;

	if (!state.games.length || !currentGame) return null;

	const { cardImg = '', GameName, MaxWin, GameType, ReleaseDate, Volatility, RTP } = currentGame;

	const maxMultiplier = currentGame['Max.multiplier'];
	const releaseDate = getCurrentDate(ReleaseDate);

	const getText = (textID) => (textID ? <FormattedMessage id={textID} /> : null);

	const filteredDetails = [
		[getText('GameType'), getText(GameType)],
		[getText('releaseDate'), releaseDate],
		[getText('Volatility'), getText(Volatility)],
		[getText('maxMultiplier'), maxMultiplier],
		['RTP', RTP],
		[getText('maxWin'), MaxWin]
	].filter((i) => i[0] && i[1]);

	const Details = ({ title, value, className }) => (
		<div className={className}>
			<span className={styles.detail_label}>{title}</span>
			<span className={styles.detail_value}>{value}</span>
		</div>
	);

	return (
		<div className={styles.game_info_container}>
			<div className={styles.game_image} style={{ backgroundImage: `url("${cardImg}")` }}></div>
			<div className={styles.details_wrapper}>
				<div className={styles.info_container}>
					<div className={styles.game_title}>{GameName}</div>
				</div>
				<div className={styles.details_container}>
					{filteredDetails.map((item, index) => {
						if (index % 2 !== 0) return null;

						const firstDetail = filteredDetails[index];
						const secondDetail = filteredDetails[index + 1];
						const shouldShowBorder = index > 1 ? styles.detail_item_left_border : styles.detail_item;

						return (
							<div key={index} className={styles.details_coll}>
								<Details title={firstDetail[0]} value={firstDetail[1]} className={shouldShowBorder} />
								{secondDetail && (
									<Details
										title={secondDetail[0]}
										value={secondDetail[1]}
										className={shouldShowBorder}
									/>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default GameDetails;
